import React, { useState } from 'react';

import {UI_OnCreateEntity, UI_GetEntityInfo} from '../gravem/gui';

function GUI(props)
{
    const [entitiesIDs, setEntitiesIDs] = useState([]);
    const [indexSelectedItem, setIndexSelected] = useState(-1);

    function OnButtonAddEntityClick()
    {
        
    }

    function OnButtonSaveEntityClick()
    {
        const entityData = {};
        entityData.name = document.querySelector("#input_entity_id").value;
        entityData.mass = parseFloat( document.querySelector("#input_entity_mass").value );
        entityData.radius = parseFloat( document.querySelector("#input_entity_radius").value );
        
        entityData.posX = parseFloat( document.querySelector("#input_entity_posx").value );
        entityData.posY = parseFloat( document.querySelector("#input_entity_posy").value );
        entityData.posZ = parseFloat( document.querySelector("#input_entity_posz").value );
        
        entityData.velX = parseFloat( document.querySelector("#input_entity_velx").value );
        entityData.velY = parseFloat( document.querySelector("#input_entity_vely").value );
        entityData.velZ = parseFloat( document.querySelector("#input_entity_velz").value );

        console.warn(entityData.mass);
        console.warn(entityData.radius);

        UI_OnCreateEntity(entityData);
    }

    function OnEntityItemSelected()
    {
        //Update info fields with selected entity data
        const entityData = UI_GetEntityInfo();
    }

    return (
        <div id="div_gui">
            
            <div id="div_gui_entities">
                <select name="list_entities" size="5" multiple="multiple"> 
                    {}
                    <option value="Merceders" selected> Sample item </option>
                    <option value="Merceders"> Sample item 2 </option>
                </select>  
                <button type="button" className="btn btn-primary" onClick={OnButtonAddEntityClick()}>+</button>
            </div>

            <div id="div_gui_entities_settings">
                <table>
                    <tr>
                        <td>Name:</td>
                        <td colspan="3"><input id="input_entity_id" type="text"></input></td>
                    </tr>
                    <tr>
                        <td>Mass:</td>
                        <td colspan="3"><input id="input_entity_mass" type="text"></input></td>
                    </tr>
                    <tr>
                        <td>Radius:</td>
                        <td colspan="3"><input  id="input_entity_radius" type="text"></input></td>
                    </tr>
                    <tr>
                        <td>Position (XYZ):</td>
                        <td><input id="input_entity_posx" type="text" /></td>
                        <td><input id="input_entity_posy" type="text" /></td>
                        <td><input id="input_entity_posz" type="text" /></td>
                    </tr>
                    <tr>
                        <td>Velocity (XYZ):</td>
                        <td><input id="input_entity_velx" type="text" /></td>
                        <td><input id="input_entity_vely" type="text" /></td>
                        <td><input id="input_entity_velz" type="text" /></td>
                    </tr>
                    
                    <tr> <td></td> <td colspan="3"> <button type="button" className="btn btn-success" onClick={()=>OnButtonSaveEntityClick()}>Save</button> </td></tr>
                    <tr> <td></td> <td colspan="3"> <button type="button" className="btn btn-danger" onClick={()=>console.log("Comp")}>Delete</button> </td></tr>
                </table>
            </div>

        </div>
    );
}

export default GUI;
