
import PhysEng from "./physeng.mjs";
import Renderer from "./renderer.mjs";
import { Entity, entities } from "./state.mjs";
import { Vector3 } from "./utils.mjs";

import "./gui.mjs";

const print = console.log;


const test_entities =
[
    {
        name: "entity0",
        mass: 50.0,
        radius: 5.0,
        position: new Vector3(0.0,0.0,0.0),
        velocity: new Vector3(0.0,0.0,0.0)
    },
    {
        name: "entity1",
        mass: 1.0,
        radius: 2.0,
        position: new Vector3(100.0,0.0,0.0),
        velocity: new Vector3(0.0,15.0,0.0)
    },
    {
        name: "entity2",
        mass: 1.0,
        radius: 2.0,
        position: new Vector3(-100.0,0.0,0.0),
        velocity: new Vector3(0.0,-15.0,0.0)
    },
    // {
    //     name: "entity3",
    //     mass: 1.0,
    //     radius: 5.0,
    //     position: new Vector3(-30.0,30.0,0.0),
    //     velocity: new Vector3(0.0,0.0,0.0)
    // },
    //{name: "entity2", mass: 10.0, radius:10.0, position: new Vector3(-10.0,5.0,0.0), velocity: new Vector3(1.0,0.0,0.0) },
];

//====================================================================================================
function main()
{
    console.log("GravEm");

    //Init state
    for (let i=0; i!=test_entities.length; ++i)
    {
        entities.push(new Entity(
            test_entities[i].name,
            test_entities[i].mass,
            test_entities[i].radius,
            test_entities[i].position,
            test_entities[i].velocity
        ));
    }

    //Init renderer
    const renderer = new Renderer();
    renderer.init();

    //Init physics
    const physEng = new PhysEng();
    physEng.init();

    window.physEng = physEng;

    //Kick-in main processing loop
    (function processIteration()
    {
        //perform modifications
        physEng.process();

        //render
        renderer.render();

        requestAnimationFrame(processIteration);
    })();
}

export default main;
