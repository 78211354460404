class Vector3
{
    constructor(par_x=0.0, par_y=0.0,par_z=0.0)
    {
        this.x = par_x;
        this.y = par_y;
        this.z = par_z;
    }

    //TIP maybe make it static?
    length()
    {
        return Math.sqrt(
            this.x*this.x 
            + this.y*this.y
            + this.z*this.z
        );
    }

    //TIP maybe make it static?
    normalize()
    {
        const invertedLength = 1 / this.length();

        const vecResult = new Vector3(
            this.x * invertedLength,
            this.y * invertedLength,
            this.z * invertedLength
        );

        return vecResult;
    }

    static add(par_vecFirst, par_vecSecond)
    {
        const vecResult = new Vector3(
            par_vecFirst.x + par_vecSecond.x,
            par_vecFirst.y + par_vecSecond.y,
            par_vecFirst.z + par_vecSecond.z
        );

        return vecResult;
    }

    static sub(par_vecFirst, par_vecSecond)
    {
        const vecResult = new Vector3(
            par_vecFirst.x - par_vecSecond.x,
            par_vecFirst.y - par_vecSecond.y,
            par_vecFirst.z - par_vecSecond.z
        );

        return vecResult;
    }

    static mul(par_vec, par_multiplier)
    {
        const vecResult = new Vector3(
            par_vec.x * par_multiplier,
            par_vec.y * par_multiplier,
            par_vec.z * par_multiplier
        );

        return vecResult;
    }
}

export {Vector3};
