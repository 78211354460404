import {Vector3} from "./utils.mjs";
import config from "./config.mjs";


class Entity
{
    constructor(par_name="", par_mass=0.0, par_radius=1.0, par_position = new Vector3(), par_velocity = new Vector3())
    {
        this.name = par_name;
        this.mass = par_mass;
        this.radius = par_radius;
        this.position = par_position;
        this.velocity = par_velocity;

        this.footprints = Array(config.footprintsAmount).fill(this.position);
    }
}


let entities = [];


export {Entity, entities};
