//Controller to communicate with GUI and maybe another stuff

import { Entity, entities } from "./state.mjs";
import {Vector3} from "./utils.mjs";

class Controller
{
    addEntity(entityData)
    {
        entities.push(new Entity(
            entityData.name,
            entityData.mass,
            entityData.radius,
            new Vector3(entityData.posX, entityData.posY, entityData.posZ),
            new Vector3(entityData.velX, entityData.velY, entityData.velZ)
        ));
    }

    getEntityInfo(entityID)
    {
        const entityData = entities.find(e => e.name === entityID);

        return entityData;
    }
};


const controller = new Controller();


export default controller;
