import React, {Component} from "react";

import gravemMain from "../gravem/main";

class Visualization extends React.Component
{
    componentDidMount()
    {
        gravemMain();
    }

    render()
    {
        return (<div id="div_visualization"></div>);
    }
};

export default Visualization;
