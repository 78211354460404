import controller from "./controller.mjs";


function UI_OnCreateEntity(entityData)
{
    controller.addEntity(entityData);
}


function UI_GetEntityInfo(entityID)
{
    const entityData = controller.addEntity(entityID);

    return entityData;
}


function GUI_OnCreateObject()
{
    console.warn("GUI_OnCreateObject()");
    //Read data from GUI-widgets
    //TODO
    let entityData = {};

    entityData.name = document.getElementById("addEntity_input_objName").value;
    //TODO add converting exception handling
    entityData.mass = parseFloat( document.getElementById("addEntity_input_objMass").value );
    
    entityData.radius = parseFloat( document.getElementById("addEntity_input_objRad").value );
    
    entityData.posX = parseFloat( document.getElementById("addEntity_input_objPosX").value );
    entityData.posY = parseFloat( document.getElementById("addEntity_input_objPosY").value );
    entityData.posZ = parseFloat( document.getElementById("addEntity_input_objPosZ").value );

    entityData.velX = parseFloat( document.getElementById("addEntity_input_objVelX").value );
    entityData.velY = parseFloat( document.getElementById("addEntity_input_objVelY").value );
    entityData.velZ = parseFloat( document.getElementById("addEntity_input_objVelZ").value );

    // console.warn("GUI_OnCreateObject(): name=", entityData.name);
    // console.warn("GUI_OnCreateObject(): mass=", entityData.mass);
    // console.warn("GUI_OnCreateObject(): rad=", entityData.mass);
    // console.warn(`GUI_OnCreateObject(): pos=(${entityData.posX} ${entityData.posY} ${entityData.posZ})`);
    // console.warn(`GUI_OnCreateObject(): vel=(${entityData.velX} ${entityData.velY} ${entityData.velZ})`);

    controller.addEntity(entityData);
}


export {UI_OnCreateEntity, UI_GetEntityInfo};
