import * as THREE from "three";
import { FlyControls } from "three/examples/jsm/controls/FlyControls.js";
import { entities } from "./state.mjs";


class Renderer{
    init()
    {
        //console.log("Renderer::init()");

        this.scene = new THREE.Scene();

        //Init renderer
        const containerElement = document.getElementById("div_visualization");
        const WIDTH = 1000;
        const HEIGHT = 1000;
        this.renderer = new THREE.WebGLRenderer({ antialias: true});
        this.renderer.setSize(WIDTH, HEIGHT);
        this.renderer.setClearColor(0x020202, 1);
        document.getElementById("div_visualization").appendChild(this.renderer.domElement);
        //Init camera
        this.camera = new THREE.PerspectiveCamera(100, WIDTH / HEIGHT, 1, 1000);
        this.camera.position.set(0,0,200);

        this.cameraControls = new FlyControls(this.camera, this.renderer.domElement);
        this.cameraControls.movementSpeed = 50;
        this.cameraControls.rollSpeed = Math.PI / 24;
        this.cameraControls.autoForward = false;
        this.cameraControls.dragToLook = true;  // set up a basic room scene
    }

    render()
    {
        //console.log("Renderer::render()");

        //DEBUG Init objects
        //const geometry = new THREE.SphereGeometry( 1, 16, 16 );
        const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } );
        const footprintMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000 } );

        const renderObjects = [];

        for (const entity of entities)
        {
            const geometry = new THREE.SphereGeometry( entity.radius, 16, 16 );
            const renderObj = new THREE.Mesh( geometry, material );
            renderObj.position.x = entity.position.x;
            renderObj.position.y = entity.position.y;
            renderObj.position.z = entity.position.z;
            renderObjects.push(renderObj);
            this.scene.add(renderObj);

            //Update entity footprint with its new position
            entity.footprints.pop();
            entity.footprints.unshift(entity.position);

            //Render footprints
            const footprintsGeometry = new THREE.BufferGeometry().setFromPoints(entity.footprints);
            const lineObj = new THREE.Line(footprintsGeometry, footprintMaterial);
            renderObjects.push(lineObj);
            this.scene.add(lineObj);
        }

        this.renderer.render(this.scene, this.camera);

        renderObjects.forEach(e=>{this.scene.remove(e)});

        this.cameraControls.update(0.01);
    }
};


export default Renderer;
