import logo from './logo.svg';
import './App.css';

import Visualization from "./components/Visualization";
import GUI from "./components/GUI";

function App() {
  return (
    <div className="App">
      <h1>Gr3m</h1>
      <Visualization />
      <GUI />
    </div>
  );
}

export default App;
