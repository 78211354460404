import { Vector3 } from "./utils.mjs";
import { entities } from "./state.mjs";

const G = 0.02;

class PhysEng
{
    init()
    {
        //console.log("PhysEng::init()");
        
        this.lastTime = Date.now();
    }

    process()
    {
        //console.log("PhysEng::process()");

        //TIP physeng can calculate deltatime itself
        const curTime = Date.now();
        //const deltaTime = curTime - this.lastTime;
        const deltaTime = 1;
        this.lastTime = curTime;

        for (let i = 0; i != entities.length - 1; ++i)
        {
            for(let j = i + 1; j != entities.length; ++j)
            {
                const vecDistance = Vector3.sub(entities[j].position, entities[i].position);
                const distance = vecDistance.length();
                if (0 === distance) { continue; }

                const gForce = (G * entities[i].mass * entities[j].mass) / (distance);

                const vecForce = Vector3.mul(vecDistance, gForce);
                entities[i].velocity = Vector3.add(
                    entities[i].velocity, 
                    Vector3.mul(vecForce, 1 * deltaTime / entities[i].mass)
                );
                entities[j].velocity = Vector3.sub(
                    entities[j].velocity,
                    Vector3.mul(vecForce, 1 * deltaTime / entities[j].mass)
                );
            }
            
            entities[i].position = Vector3.add(entities[i].position, entities[i].velocity);
            // console.log(`Entities[${i}]: `, entities[i].position);
            // console.log(`Entities[${i}] vel: `, entities[i].velocity);
        }
        entities[entities.length-1].position = Vector3.add(entities[entities.length-1].position, entities[entities.length-1].velocity);
        // console.log(`Entities[${entities.length-1}]: `, entities[entities.length-1].position);
        // console.log(`Entities[${entities.length-1}] vel: `, entities[entities.length-1].velocity);
        
    }
};


export default PhysEng;
